@use '../abstracts' as *;
.bg--dark {
    background-color: var(--bg--dark);
}
.leftbarlogo__container {
    @include flexBox(flex, column, wrap);
    align-items: center;
    justify-content: center;
    padding: 1rem 1rem 1rem;
    background-image: linear-gradient(321deg, #fafbfc 17.84%, #e3ecf2 79.27%);
    @include respond(tablet-md) {
        padding: 0rem 2rem;
        min-height: 100vh;
    }
}

.glass__tab {
    border-radius: 16px;
    width: 100%;
    padding: 0rem;
    margin: auto;
    height: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > img {
        max-height: 100%;
        max-width: 200px;
        margin: 0 auto;
        @include respond(tablet-md) {
            max-width: 100%;
        }
    }
    @include respond(tablet-md) {
        height: 300px;
        padding: 2rem;
    }
    @include respond(tablet-lg) {
        height: 450px;
        max-width: 450px;
        --size: 200px;
        max-height: var(--size);
        svg {
            max-height: calc(var(--size) - 80px);
        }
    }
    @include respond(tablet-md) {
    --size: 300px;
    }
    @include respond(tablet-lg) {
        --size: 450px;
    }
}
.login__innerarea {
    align-self: flex-start;
    padding: 0rem 1rem 2rem;
    @include respond(tablet-md) {
        min-height: 100vh;
        @include flexBox(flex, column, wrap);
        align-items: center;
        justify-content: center;
        &.justify-content-loginstart {
            @include respond(desktop-lg) {
                padding-top: 12rem;
                justify-content: flex-start;
                .login--wrapperbox {
                    &.max--760 {
                        max-width: 480px;
                    }
                }
            }
        }
    }
}
.authentication__wrapper {
    min-height: auto;
    @include respond(desktop-lg) {
        height: auto;
        min-height: 100vh;
    }
}

.display1--text {
    font-weight: 600;
    font-size: 1.75rem;
    margin: 0;
    line-height: normal;
    color: var(--bg--dark);
    @include respond(tablet-md) {
        font-size: 2.25rem;
    }
}
