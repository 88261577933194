@use '../abstracts' as *;

.menuItem--list {
    margin-bottom: 1rem;
    margin-top: 1rem;
    @include respond(tablet-lg) {
        margin-top: 2.5rem;
    }
}

body {
    aside {
        .sidebarFixed {
            .MuiPaper-root {
                border-right: 1px solid white;
                background-color: var(--sidebar-background);
            @media(min-width: 900px) {
                width: 300px;
                }
            }
        }
    }
}

