@use '../abstracts' as *;

div {
    &[aria-describedby="alert-dialog-order"] {
        background: #232157;
        padding: 20px;
        overflow: initial;
        > h2 {
            font-weight: 600;
            font-size: 1.25rem;
            padding: 10px;
        }
        > .MuiDialogContent-root {
            padding-left: 10px;
            padding-bottom: 0;
            margin-top: 0px;
            > p {
                .row {
                    justify-content: center;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: 15px;
                    margin-top: 15px;
                    > div {
                        font-size: 15px;
                        font-weight: 500;
                        opacity: 1;
                        color: #ffffff;
                        border: 1px solid #2e2d5e;
                        background: #7370b8;
                        padding: 7px 21px;
                        border-radius: 8px;
                        cursor: pointer;
                    }
                    button {
                        margin-top: 25px;
                    }
                }
            }
        }
        & > {
            .MuiBox-root {
                bottom: 0 !important;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}







