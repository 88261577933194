@use '../abstracts' as *;

.input--label {
    color: var(--bg--primary);
    font-weight: 400;
    font-size: .875rem;
    margin-bottom: 0;
    line-height: normal;
    display: block;
    opacity: .7;
}

.input--transparent, div.ReactTags__tagInput input.ReactTags__tagInputField {
    background-color: var(--brand--font--white);
    padding: 1.15em 1.25em;
    border-radius: .875em;
    font-size: 1rem;
    font-weight: 500;
    color: var(--bg--dark);
    border: 1px solid var(--brand--peace);
    transition: .13s ease-in all;
    resize: none;
    width: 100%;
    max-width: 100%;
    &:hover {
        border-color: var(--bg--primary)
    }
    &:focus {
        outline: none;
        box-shadow: none;
        border-color: var(--bg--primary);
    }
    &::placeholder {
        color: var(--brand--peace);
        font-weight: 400;
    }
    &[disabled] {
        background-color: #EEF4F8;
        cursor: not-allowed;
    }
}

.error--pill {
    font-size: .8rem;
    font-weight: 500;
    display: block;
    margin-top: 0.25rem;
    &.text--error {
        color: var(--brand--danger);
    }
    &.text--success {
        color: var(--brand--success);
    }
}

.MuiFormControl-root {
    .MuiFormGroup-root {
        flex-direction: row;
        .col-6 {
            + .col-6 {
                .MuiFormControlLabel-root {
                    padding-left: 1rem;
                }
            }
        }
    }
}

.MuiBackdrop-root {
    background: rgba(0,0,0,0.75) !important;
}
.support--text {
    color: var(--bg--primary);
    font-size: .875rem;
    margin-bottom: 0rem;
    line-height: normal;
    opacity: .5;
    font-weight: 400;
}
