:root {
    color-scheme: light dark;
    --brand--primary: #00406C;
    --brand--seondary: rgba(246, 67, 59, 1);
    --brand--danger: rgba(255, 39, 91, 1);
    --brand--peace: #D7DAE3;
    --brand--gray: #818589;
    --brand--accent: #9DB2CE;
    --brand--success: #5AAA77;
    --brand--font--white: rgba(255, 255, 255, 1);
    --brand--font--link: #0176B8;
    --bg--primary: #032D49;
    --bg--dark: #1D1617;

    // index.css
    --white: #ffffff;
    --black: #000000;
    --border: #E5E7EB;
    --text: #2D3748;
    --tabs-dark: #181B1E;
    --sidebar-background: #F4F6F9;
    --navy-blue: #003CD6;
    --navy-blue-light: #F0F4FF;
    --active-green: #18B472;
    --active-green-light: #18b47337;
    --inActive-red: #CD0E3C;
    --inActive-red-light: #cd0e3b32;
    --gray-light: #C8CFE3;
    --gray-dark: #4B5161;
    --gray-text: #9EA3AB;
    --yellow-dark: #FBB64E;
    --yellow-light: #F8EAD4;
    --red-dark: #FF3A51;
    --red-light: #FED5DA;
    --disabled-light: #EBEBEB;
    --disabled-dark: #D1CBCC;
}