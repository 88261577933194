// respond is the name of mixin
@mixin respond ($breakpoint) {
    @if $breakpoint==mobile {
        @media only screen and (min-width: 36em) {
        @content;
        }
    }
    @if $breakpoint==tablet-md {
        @media only screen and (min-width: 48em) {
        @content;
        }
    }
    @if $breakpoint==max-tablet-md {
        @media only screen and (max-width: 767px) {
        @content;
        }
    }
    @if $breakpoint==tablet-lg {
        @media only screen and (min-width: 62rem) {
        @content;
        }
    }
    @if $breakpoint==desktop-lg {
        @media only screen and (min-width: 75rem) {
        @content;
        }
    }
    @if $breakpoint==desktop-xl {
        @media only screen and (min-width: 87.5em) {
        @content;
        }
    }
    @if $breakpoint==desktop-xxl {
      @media only screen and (min-width: 105em) {
      @content;
      }
  }
    @if $breakpoint==max-desktop-lg {
        @media only screen and (max-width: 1199px) {
        @content;
        }
    }
}


// Breakpoints
$breakpoints: (
  'sm': 36em, // Set sm breakpoint's max width = 576px
  'md': 48em, // Set md breakpoint's max width = 768px
  'lg': 62em, // Set lg breakpoint's max width = 992px
  'xl': 75em, // Set xl breakpoint's max width = 1200px
  'xxl': 90em // Set xxl breakpoint's max width = 1440px
);

// Grid Defaults
$column-count: 12 !default;
$gutter-size: .5rem !default;

// Function to calculate column width
@function find_column_width($columns: $column-count) {
  @return calc(100% / $columns);
}

// Mixin for grid container
@mixin grid($nested: false, $gutters: true) {
  display: flex;
  flex-wrap: wrap;
  margin-left: -$gutter-size;
  margin-right: -$gutter-size;

  @if $nested {
    flex: 0 1 auto;
  }

  @if $gutters {
    > * {
      padding-left: $gutter-size;
      padding-right: $gutter-size;
    }
  }
}

// Mixin for grid column
@mixin column($columns: 0, $breakpoints: ()) {
  $width: find_column_width($column-count);
  
  // Default column
  @if $columns == 0 {
    flex: 1 0 0;
    width: auto;
  } @else {
    $flex-basis: $width * $columns;
    flex: 0 0 $flex-basis;
    min-width: $flex-basis;
  }

  // Breakpoint columns
  @each $breakpoint, $value in $breakpoints {
    @if $value != null {
      @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
        $flex-basis: $width * $value;
        @if $value == 0 {
          flex: 1 0 0;
          width: auto;
        } @else {
          flex: 0 0 $flex-basis;
          min-width: $flex-basis;
        }
      }
    }
  }
}

// Utility mixin for breakpoint columns
@mixin mq-column($breakpoint, $columns: 0) {
  $width: find_column_width($column-count);
  $flex-basis: $width * $columns;

  @media screen and (min-width: map-get($breakpoints, $breakpoint)) {
    @if $columns == 0 {
      flex: 1 0 0;
      width: auto;
    } @else {
      flex: 0 0 $flex-basis;
      min-width: $flex-basis;
    }
  }
}

// Base column classes
@for $i from 1 through $column-count {
  .col-#{$i} {
    @include column($i);
  }

  @each $breakpoint, $size in $breakpoints {
    .#{$breakpoint}-col-#{$i} {
      @include mq-column($breakpoint, $i);
    }
  }
}


// Flexbox Mixin
@mixin flexBox($display, $direction, $wrap) {
  display: $display;
  flex-direction: $direction;
  flex-wrap: $wrap;
}

// Usage
.row {
  @include grid();
}

.column {
  @include column();
}




