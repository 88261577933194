@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


.MuiDataGrid-virtualScroller {
  overflow: auto !important;
}

.MuiDataGrid-filler>div {
  border-top: 1px solid transparent !important;
}

*::-webkit-scrollbar {
  width: 12px !important;
}

*::-webkit-scrollbar-track {
  background: #e0e0e0 !important;
}

*::-webkit-scrollbar-thumb {
  background: #888 !important;
  border-radius: 6px !important;
}

*::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

* {
  scrollbar-width: thin !important;
  scrollbar-color: #888 #e0e0e0 !important;
  -ms-overflow-style: -ms-autohiding-scrollbar !important;
}


.commonShadow {
  box-shadow: 6px 6px 54px 0 rgba(0, 0, 0, 0.03) !important;
}

.commonShadowLight {
  box-shadow: 0px 0px 2px 0 rgba(0, 0, 0, 0.5) !important;
}

.twilio-selector svg,
.twilio-selector div,
.twilio-selector span,
.twilio-selector>div,
.twilio-selector>div>span,
.form-control-selecter svg,
.form-control-selecter div,
.form-control-selecter .MuiInputLabel-root,
.form-control-selecter span,
.form-control-selecter .Mui-focused {
  color: #023dd6 !important;
  background: #c1d0f51d !important;
  font-weight: 600 !important;
}

.twilio-selector {
  min-width: 200px;
}

.twilio-selector .Mui-focused .MuiOutlinedInput-notchedOutlin,
.twilio-selector fieldset {
  border-color: #023dd6 !important;
  border-width: 2px !important;
}

.twilio-selector :focus-visible {
  outline: none !important;
}

.sideBar-custom-target .ps-submenu-expand-icon>span {
  width: 10px !important;
  height: 10px !important;
}

.sideBar-custom-target .ps-menu-button:hover {
  background-color: none !important;
  cursor: pointer !important;
}

.sideBar-custom-target .ps-submenu-content {
  background: var(--sidebar-background) !important;
}

.sideBar-custom-target>aside {
  background-color: transparent !important;
  border-right-width: 0px;
  border-right-style: solid;
  width: 100% !important;
  border-color: #efefef;
}

.sidebar-project-button>a {
  background-color: var(--sidebar-background) !important;
  padding-right: 29px !important;
  padding-left: 40px !important;
}

.primaryHoverButton {
  transition: 0.5s ease-in-out;
  border: 2px solid var(--navy-blue) !important;
  box-shadow: 0 0 0 0 var(--navy-blue);
}

.primaryHoverButton:hover {
  box-shadow: 0 0 0 0 var(--navy-blue);
  color: white !important;
  transition: 0.5s ease-in-out;
  background-color: var(--navy-blue) !important;
}

.dataGridTable {
  border-width: 0px !important;
  border-style: none !important;
}

.MuiDataGrid-container--top [role=row] {
  background-color: #F3F5F8 !important;
}

.MuiDataGrid-row {
  --rowBorderColor: #E2E8F0 !important;
}

.MuiDataGrid-columnHeaders::after,
.MuiDataGrid-topContainer::after {
  background-color: #f3f5f82f !important;
}

.MuiDataGrid-withBorderColor {
  border-color: #E2E8F0 !important;
}

.MuiDataGrid-iconButtonContainer>button,
.MuiDataGrid-columnSeparator,
.MuiTablePagination-actions>button {
  color: black !important;
}

.MuiTablePagination-actions .Mui-disabled {
  color: #E2E8F0 !important;
}

.MuiInputBase-colorPrimary>.MuiTablePagination-select,
.dataGridTable .MuiInputBase-colorPrimary>svg {
  color: black !important;
}

.MuiDataGrid-columnHeader:focus,
.css-uffxte-MuiDataGrid-root .MuiDataGrid-cell:focus,
.MuiDataGrid-cell:focus-within,
.MuiDataGrid-columnHeader:focus,
.MuiDataGrid-columnHeader:focus-within {
  outline: none !important;
}

.disabled {
  opacity: 0.3 !important;
  pointer-events: none !important;
}

.title-textField p,
.title-textField-description p,
.quill-editor-error {
  font-weight: 500;
  padding: 4px 0px !important;
  margin: 0;
  color: var(--red-dark);
}

.quill-editor-error {
  font-weight: 600 !important;
}

.title-textField .Mui-error .MuiOutlinedInput-notchedOutline,
.title-textField-description .Mui-error .MuiOutlinedInput-notchedOutline {
  border-width: 2px !important;
}

.title-textField input,
.title-textField-description textarea {
  border: 1px solid var(--border);
  border-radius: 7px;
  color: var(--text);
  padding: 11.5px 14px !important;
  font-weight: 400;
}

.title-textField-description>div {
  padding: 0px !important;
}

.common-overflow-text {
  /* max-width: 220px; */
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.tabsBoxes {
  display: inline-flex !important;
  -webkit-align-items: start !important;
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: start !important;

}

.verticalTabsSelector-notifier .MuiTabs-indicator {
  width: 0px !important;
}

.textfield-value-notifier input {
  color: var(--red-dark) !important;
}

.textField-key-notifier input {
  color: var(--navy-blue) !important;
}

.date-range-picker input,
.selector-action-stype,
#demo-multiple-checkbox {
  color: var(--gray-dark) !important;
  padding: 8px 14px !important;
  font-weight: 500 !important;
}

.MuiDateRangePickerDay-rangeIntervalPreview .Mui-selected {
  color: var(--white) !important;
}

.date-range-picker button svg,
.MuiPickersArrowSwitcher-root>button>svg {
  fill: var(--black) !important;
}

.MuiDateRangePickerDay-rangeIntervalPreview button {
  color: var(--gray-dark) !important;
}

.MuiDateRangeCalendar-root>div {
  color: transparent !important;
}

.dateRangeSet>div>div {
  border: 1px solid var(--border) !important;
  border-radius: 8px !important;
  overflow: hidden !important;
  min-width: 300px !important;
  max-width: 300px !important;
}

.dateRangeSet>div>div>div {
  border-radius: 8px !important;
}

.MuiDateRangePickerDay-rangeIntervalPreview .Mui-disabled {
  opacity: 0.3 !important;
}

.ql-toolbar {
  background: #80808028 !important;
}

.ql-container {
  min-height: 200px !important;
}

.ql-formats {
  background: #8080802e !important;
  border-radius: 4px !important;
  padding: 2px !important;
  margin: 6px !important;
}


.quillTextarea {
  border: 1px solid var(--border) !important;
  border-radius: 8px !important;
  padding: 20px !important;
  line-height: 30px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  width: 100% !important;
  color: var(--red-dark) !important;
  font-weight: 600 !important;
  max-height: 300px !important;
}

/* css code might be used for datagrid table   */
/* .hiddenColumn{
  display: none !important;
}

.MuiDataGrid-columnHeader, .MuiDataGrid-cell{
  min-width: 270px !important;
} */

.MuiDataGrid-menuIcon button svg {
  color: var(--gray-light) !important;
  display: none !important;
}

.ql-snow .ql-tooltip input[type=text] {
  background-color: var(--white) !important;
  border: 2px solid var(--gray-light) !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  border-radius: 6px !important;
  height: 35px !important;
  margin: 0px;
  padding: 6px 8px !important;
  width: 170px;
}

.ql-snow a {
  font-weight: 600 !important;
  color: var(--navy-blue) !important;
}

.ql-container.ql-snow {
  border: 2px solid #ccc !important;
  max-height: 600px !important;
  overflow: hidden !important;
  overflow-y: auto !important;
  overflow-x: hidden !important;
  word-wrap: break-all !important;
  word-break: break-all !important;
  overflow-wrap: break-all !important;
}


/* Typography */
.title-3 {
  @apply !text-2xl !font-bold;
}

.title-4 {
  @apply !text-xl !font-bold;
}

.title-5 {
  @apply !text-base !font-medium;
}

.title-6 {
  @apply !text-sm !flex !font-medium;
}

.tab-title {
  @apply !font-bold !normal-case
}

/* Active Inactive Status */
.online-status {
  @apply !h-[6px] !w-[6px] !rounded-full !inline-block;
}

.status-badge {
  @apply px-3 py-1 font-normal text-xs flex gap-2 items-center w-fit text-center rounded-full border;

  &.active {
    @apply text-[var(--active-green)] border-[var(--active-green-light)];
  }

  &.inactive {
    @apply text-[var(--inActive-red)] border-[var(--inActive-red-light)]
  }
}

/* Modal Styling */
.modal-container {
  @apply !w-full !h-screen !table;
}

.modal-wrapper {
  @apply !w-full !p-[144px] !flex !justify-center;
}

.modal-body {
  @apply !flex-[0.4_1_0%] !bg-white !p-[30px] !rounded-[15px]
}

.project-detail-modal {
  @apply !absolute !top-1/2 !left-1/2 !transform !-translate-x-1/2 !-translate-y-1/2 !rounded-[6px] !p-5 !w-full !max-w-[540px]
}


.common-form-card {
  @apply !w-full !gap-28 !flex !border-[var(--border)] !pb-10 !border-b;
}


.required-asterisk {
  @apply !pl-1 !flex text-[var(--red-dark)];
}

.breadcrum-text {
  @apply !w-fit !text-base !font-medium !text-[var(--black)] !flex;
}

.notifier-section-card {
  @apply !w-full !mt-8 !gap-28 !flex !border-[var(--border)] !pb-10 !border-b
}

.tabs-content-heading {
  @apply !flex !flex-grow !mt-5 !ml-[10px] !items-center;
}

/* Common buttons */
.login-btn {
  @apply cursor-pointer py-4 px-12 text-base inline-block font-semibold rounded-[10px] border-none outline-none relative overflow-hidden shadow-[2px_2px_12px_rgba(0_,64_,108_,0.19)] hover:bg-[#0a5587] hover:shadow-xl transition-all duration-300 ease-in;
  color: var(--brand--font--white);
  background-color: var(--brand--primary);
}

.login-btn:focus {
  @apply bg-[#0a5587] shadow-xl;
}

.cancel-btn {
  @apply !text-[13px] !font-medium !w-[30%] !mr-[20px] !bg-white !mt-6 !text-[#737A8D];
}

.save-btn {
  @apply !text-[13px] !font-medium !min-w-[128px] !bg-[#003CD6] !mt-6 !text-white;
}

.delete-btn {
  @apply !text-[13px] !font-medium w-[30%] !bg-[#e81c1cb8] !mt-6 !text-white;
}

.text-btn {
  @apply !text-[var(--navy-blue)] !mt-8 !ml-auto !underline !gap-2 !py-3 hover:!bg-[#F2F5FD] !items-center !text-sm !font-bold !capitalize !flex;
}

.add-project-btn {
  @apply !rounded-[5px] !text-center !font-bold !w-full !h-[35px] !mt-6 !text-[#003CD6] !text-sm !py-2 !flex !gap-3 !items-center;
}

.logout-btn {
  @apply !text-[#3A3C3F] !rounded-[5px] !mt-[15px] h-[35px] !w-full !font-bold !text-sm !text-center !items-center !flex !gap-3
}

.log-action-btn {
  @apply !rounded !cursor-pointer !text-xs hover:!bg-[var(--gray-light)] !font-normal !w-fit !text-[var(--gray-dark)] !py-2 !px-2 !border-[var(--gray-light)] !border-2 !border-solid
}

.delete-action-btn {
  @apply !rounded !cursor-pointer !w-fit hover:!bg-[var(--red-light)] !text-[var(--gray-dark)] !border-[var(--red-light)] !py-1 !px-2 !border-2 !border-solid
}

.view-action-btn {
  @apply !rounded !cursor-pointer !w-fit hover:!text-[var(--white)] hover:!bg-[var(--yellow-light)] !text-[var(--gray-dark)] !border-[var(--yellow-light)] !py-1 !px-2 !border-2 !border-solid;
}