@use '../abstracts'as *;

.login--wrapperbox {
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
}

.h-inherit {
    height: inherit;
}

.max--760 {
    max-width: 760px;
}

.MuiDataGrid-selectedRowCount {
    visibility: hidden !important;
}

.cursor__pointer .MuiDataGrid-row {
    cursor: pointer;
}

.shw-dtl-dt-rng {
    font-size: 14px;
    line-height: normal;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 1.5rem;
    display: inline-block;
    background: rgb(255, 255, 255, 0.03);
    padding: 0.6em 1em;
    border-radius: 4px;
}

.max-w-180 {
    max-width: 200px !important;
}

.text--dark {
    color: var(--bg--dark);
}

.creator__side {
    width: 80%;
}

.position--relative {
    position: relative;
}

.table__actionbuttons {
    margin: 20px 0px 10px;
    padding: 0px 15px;
    text-align: end;

    @media only screen and (min-width: 900px) {
        margin: 0;
        position: absolute;
        top: 40px;
        right: 40px;
        z-index: 1;
        padding: 0;
    }

    @include respond(tablet-lg) {
        top: 70px;
        right: 70px;
    }
}

.MuiLinearProgress-root {
    >span {
        background: var(--brand--success);
    }
}

.red--whiteIcon {
    svg {
        color: #fff;
    }
}

.MuiDataGrid-iconSeparator {
    visibility: hidden;
}

.css-i4bv87-MuiSvgIcon-root {
    padding: 0px;
    border: none;
}

.button.Mui-selected {
    background-image: linear-gradient(132.14deg, #045C9C 20.93%, #4B923F 90.47%);

}

.MuiDataGrid-footerContainer {
    .MuiToolbar-gutters {
        .MuiTablePagination-selectLabel {
            & ~ {
                div {
                    [class^="MuiTablePagination-select"] {
                        * {
                            color: var(--bg--dark);
                        }
                    }
                }
            }
        }
    }
}

.css-8vni76 .MuiDataGrid-columnHeader:focus, .css-8vni76 .MuiDataGrid-cell:focus {
    outline: none !important;
}
